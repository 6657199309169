import React from 'react'
import '../style/component.less';
import fetch from '../fetch/axios';
import { Radio } from 'antd';

class HeaderUsage extends React.Component {
  state = {
    GPTUsage: {},
    GPT4Usage: {},
    type: "GPT",
    show: true
  }
  componentDidMount() {
    // 绑定用量方法
    if (this.props?.showWidth ===768 && document.body.clientWidth < 768) {
      // 屏幕宽度小于768px时
      this.setState({show: true})
      this.props?.setCheckUsage(this.checkUsage.bind(this));
      this.checkUsage();
    } else if (this.props?.showWidth >768 && document.body.clientWidth > 768) {
      // 屏幕宽度大于768px时
      this.setState({show: true})
      this.props?.setCheckUsage(this.checkUsage.bind(this));
      this.checkUsage();
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.reducer.used.type !== state.type) {
      return {
      }
    }
    return null
  }
  componentDidUpdate() {
    if (this.props.reducer.used.type !== this.state.type) {
      // console.log("componentDidUpdate", this.props.reducer.used.type)
      this.setState({type: this.props.reducer.used.type})
    }
  }
  
  checkUsage() {
    fetch.get(`${process.env.URL}/api/chat/GPTUsage`).then((res) => {
      if (res) {
        let usageArr = res.data;
        usageArr?.forEach((item) => {
          if (item.gpt_model === "GPT") {
            this.setState({GPTUsage: item})
          } else if (item.gpt_model === "GPT_4") {
            this.setState({GPT4Usage: item})
          }
        })
      }
    })
  }
  changeType(e) {
    this.setState({type: e.target.value})
    // console.log(this.props)
    this.props?.setUsage({type: e.target.value})
  }

  render() {
    const { t } = this.props;
    const role = this.props.reducer.user.role;
    return (
      <div className={`usage_box ${this.props.className||""}`}>
        {this.state.show ?
          <div className='header_usage'>
            <div className='hu_title'>{t('usage.Usage')}</div>
            {this.state.type === "GPT" ?
              <div className='hu_text'>{this.state.GPTUsage?.token_count || "0"} / {this.state.GPTUsage?.total_count || "0"} {t('usage.token')}</div> :
              <div className='hu_text'>{this.state.GPT4Usage?.token_count || "0"} / {this.state.GPT4Usage?.total_count || "0"} {t('usage.token')}</div>
            }
          </div>
          : null}
        <div className='header_divider'></div>
        <div className='header_type'>
          <div className='ht_title'>{t('usage.Configuration')}</div>
          <div className='ht_select'>
            <Radio.Group onChange={(e) => { this.changeType(e) }} value={this.state.type}>
              <Radio value={"GPT"}>{t('usage.ChatGPT')}</Radio>
              <Radio value={"GPT_4"} disabled={role&&role.trim() !== 'System'}>{t('usage.GPT-4')}</Radio>
            </Radio.Group>
          </div>
        </div>
      </div>
    )
  }
}

export default HeaderUsage