import React from 'react'
import { MenuFoldOutlined, MenuUnfoldOutlined, PlusCircleOutlined, QuestionCircleOutlined, BulbOutlined} from '@ant-design/icons';
import { Layout, Menu, Button } from 'antd';
// import icon from '../images/icon.js';
// import fetch from '../fetch/axios';
import logo from '../images/NovaGPT_logo_white.svg';
import ic_bot from '../images/icon/hd_ic_bot.svg';
import ic_chat from '../images/icon/hd_ic_chat.svg';
import ic_plaza from '../images/icon/hd_ic_plaza.svg';
import ic_support from '../images/icon/hd_ic_support.svg';
import '../style/navGuide.less';
import HeaderUsage from '../components/usage'


// const MyIcon = createFromIconfontCN({
//   scriptUrl: '//at.alicdn.com/t/c/font_4036116_bbbix2q5bs9.js', // 在 iconfont.cn 上生成
// });

const { Sider } = Layout;

class NavigationBar extends React.Component {
  state = {
    pathname: "",
    selectedKeys: ['projects', 'dashboard'],
    role: "",
    lastOpen: ['square', 'dashboard'],

    changeNav: false, // 是否切换导航
  }
  componentDidMount() {
    // console.log(this.props);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location.pathname !== state.pathname) {
      return {
      }
    }
    if (props.user.role !== state.role) {
      return {
      }
    }
    return null
  }
  componentDidUpdate() {
    if (this.props.location.pathname !== this.state.pathname) {
      this.setState({ pathname: this.props.location.pathname }, () => {
        if (this.state.pathname === '/' || this.state.pathname === '/Library' || this.state.pathname === '/welcome') {
          if (this.state.pathname !== '/Library') {
            this.props.history.push('/Library')
          }
          this.setState({ changeNav: true, selectedKeys: ['nav-plaza']})
        } else {
          this.listenRoute(this.state.pathname);
        }
      })
    }
    if (this.props.user.role !== this.state.role) {
      this.setState({ role: this.props.user.role })
    }
  }

  listenRoute(path) {
    if (path.includes('/Library')) {
      this.setState({selectedKeys: ['nav-plaza']})
    } else if (path.includes('/faq')) {
      this.setState({selectedKeys: ['faq', 'support']})
    } else {
      this.setState({selectedKeys: ['dashboard']})
    }
  }

  changeNavPath(e) {
    // console.log(e);
    // 第二菜单
    if (e.key === "nav-plaza") {
      this.props.history.push('/Library');
    } else if (e.key === "nav-chat") {
      window.open(`http://chat.novagpt.ai/#/login?uid=${window.localStorage.getItem('uid')}`)
    } else if (e.key === "nav-bot") {
      // this.props.history.push('projects/list');
      window.open(`${window.location.origin}/#/projects/list`)
    } else if (e.key === "faq") {
      this.props.history.push('/faq');
    } else if (e.key === "contact") {
      window.open("https://www.novagpt.ai/contact-us")
    }

    if (this.props.smallScreen) {
      this.props.setNavData({collapsed: true});
    }
  }

  changePath() {
    this.props.history.push('/Library');
  }
  changeCreate() {
    this.props.history.push('/create');
  }
  closeNav(e) {
    if (e.target?.className === "navigation_guide" && this.props.smallScreen) {
      this.props.setNavData({collapsed: true});
    }
  }
  clickSubMenu(arr) {
    // console.log(arr)
    let lastArr = this.state.lastOpen;
    // console.log(lastArr)
    // 点开了广场页面
    if (arr.includes('square') && !lastArr.includes('square')) {
      this.props.history.push('/square')
    }
    this.setState({lastOpen: arr})
  }

  render() {
    const { collapsed, smallScreen, moveToOpen, t } = this.props;
    const systemArr = [
    ]
    const secondMenu = [
      {
        key: 'nav-plaza',
        icon: <img src={ic_plaza} alt=''></img>,
        label: t('nav.Bot Plaza'),
      },
      {
        key: 'nav-chat',
        icon: <img src={ic_chat} alt=''></img>,
        label: t('nav.NovaChat'),
      },
      {
        key: 'nav-bot',
        icon: <img src={ic_bot} alt=''></img>,
        label: t('nav.NovaBot'),
      },
      {
        key: 'support',
        icon: <img src={ic_support} alt=''></img>,
        label: t('nav.Support'),
        children: [
          // {
          //   key: 'welcome',
          //   icon: <ReadOutlined />,
          //   label: t('nav.Read Me'),
          // },
          {
            key: 'faq',
            icon: <QuestionCircleOutlined />,
            label: t('nav.FAQ'),
          },
          {
            key: 'contact',
            icon: <BulbOutlined />,
            label: t('nav.Contact Us'),
          }
        ]
      }
    ]
    return (
      <div className="navigation_guide" open={!collapsed} onClick={(e)=>{ this.closeNav(e); }}>
        <Sider
          trigger={null}
          breakpoint='xl'
          onBreakpoint={(broken) => {
            this.props.setNavData({collapsed: broken, smallScreen: broken});
          }}
          width={'16.25rem'}
          collapsedWidth={smallScreen ? 0: '5.25rem'}
          collapsible
          collapsed={collapsed}
          onMouseEnter={()=>{
            if (smallScreen) return;
            this.props.setNavData({collapsed: false})
          }}
          onMouseLeave={(e)=>{
            if (smallScreen || !moveToOpen) return;
            this.props.setNavData({collapsed: true})
          }}
          style={{ background: "#111111" }}
          >
          <div className={collapsed ? 'logo_box collapsed':'logo_box'}>
            <div className='icon_box' onClick={()=>{ this.changePath() }}>
              <img src={logo}  className='logo' alt=''></img>
            </div>
            {/* <CloseOutlined className='nav_close'/> */}
            {React.createElement(collapsed || moveToOpen ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'nav_close',
              onClick: () => {
                if (!collapsed && moveToOpen) {
                  this.props.setNavData({collapsed: false, moveToOpen: false})
                  return
                }
                this.props.setNavData({collapsed: !collapsed, moveToOpen: !collapsed})
              },
            })}
          </div>
          <Button icon={<PlusCircleOutlined />} type='primary' className='nav_create' onClick={()=>{ this.changeCreate() }} >{!collapsed&&t('nav.Create Bot')}</Button>
          <Menu
            theme="dark"
            mode="inline"
            // defaultSelectedKeys={}
            defaultOpenKeys={['square', 'dashboard', 'support', 'settings']}
            selectedKeys={this.state.selectedKeys}
            style={{ fontSize: ".9375rem", color: "#6f6b7d", background: "#111111" }}
            onClick={(e)=>{ this.changeNavPath(e) }}
            onOpenChange={(e)=>{ this.clickSubMenu(e) }}
            items={this.state.role === "System" ?  [...secondMenu, ...systemArr] : [...secondMenu]}
          />
          <HeaderUsage {...this.props} showWidth={1024} className={collapsed ? 'collapsed':''}></HeaderUsage>
          <div className={collapsed ? 'nav_power collapsed':'nav_power'}>
            {t('nav.Power 1')} <a href="https://www.novagpt.ai/" target="_blank" rel="noopener noreferrer">{t('nav.NovaTech')}</a> {t('nav.Power 2')}
          </div>
        </Sider>
      </div>
    )
  }
}

export default NavigationBar;