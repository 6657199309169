import React from 'react'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import Nav from '../components/nav'
import Header from '../components/header'
// import Welcome from '../pages/other/welcome'
import { withRouter } from 'react-router-dom'
import fetch from '../fetch/axios'
import { Layout } from 'antd';
import '../style/app.less'
import Chat from "./chat/chat";
import { withTranslation } from "react-i18next"

import NavGuide from '../components/navGuide'
import HeaderGuide from '../components/headerGuide'

const { Content } = Layout;

class App extends React.Component {
  state = {
    userinfo: {},
    isEmbed: false,
    chatObj: {
      subjectGUID: "",
    },
    pId: "",
    isChecking: false,
    isAuthenticated: false,

    pathname: "",
    guideVisible: false,
  }

  componentDidMount() {
    // 判断是否是本地环境
    if (window.location.hostname === 'localhost') {
      this.setState({ isAuthenticated: true })
    }
    // 判断路由
    if (window.location.hash.includes('#/chat/')) {
      // 识别bot信息
      this.setState({isEmbed: true},()=>{
        this.setState({chatObj: {}})
      });
      let params = new URLSearchParams(window.location.hash.split('?')[1]);
      let pId = params.get('pId');
      this.setState({pId})
    } else {
      // 存储用户ID
      if (window.location.hash.includes("#/login?")) {
        let uid = this.getParams('uid');
        if (uid) {
          window.localStorage.setItem('uid', uid)
          // 获取token
          this.getToken(uid, () => {
            this.props.history.push("/Library");
          });
        } else {
          this.props.userLogOut("redirect");
        }
      } else {
        let uid = window.localStorage?.getItem('uid');
        if (uid) {
          this.getToken(uid);
        } else {
          this.checkLogin();
        }
      }

      //  // 切换tab时重新获取token信息
      // document.addEventListener('visibilitychange', () => { // 浏览器切换事件
      //   this.checkLogin();
      // });
      // // 路由切换时重新获取token信息,最小间隔2秒
      // let timeStart = new Date().getTime()
      // this.props.history.listen(route => {
      //   let timeEnd = new Date().getTime()
      //   if (timeEnd - timeStart > 2000) {
      //     // console.log(timeEnd - timeStart)
      //     this.checkLogin();
      //     timeStart = new Date().getTime()
      //   }
      // })

      // 每四分钟刷新 调整为每40小时刷新一次
      setInterval(() => {
        this.checkLogin();
      }, 40*60*60*1000);
    }
    // 设置页面字体大小
    this.setFontSize(16);
  }

  componentDidUpdate() {
    if (this.props.location.pathname !== this.state.pathname) {
      this.setState({ pathname: this.props.location.pathname }, () => {
        if (this.state.pathname ==="/faq") return;
        if (this.state.pathname === '/' || this.state.pathname === '/Library' || this.state.pathname === '/welcome') {
          this.setState({ guideVisible: true })
        } else {
          this.setState({ guideVisible: false })
        }
      })
    }
  }

  // 动态设置字体大小
  setFontSize(size) {
    // 获取当前屏幕宽度
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    // 计算根元素的字体大小，例如假设 1rem 等于 10px
    const rootFontSize = screenWidth / 375 * 10; // 375 是设计稿宽度
    // 设置根元素的字体大小
    document.documentElement.style.fontSize = `${size || rootFontSize}px`;
  }

  getParams(str) {
    const params = new URLSearchParams(window.location.hash.split('?')[1]);
    return params.get(str);
  }

  getGuid() {
    return 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random()*16|0, v = c === 'x' ? r : ((r&0x3)|0x8);
      return v.toString(16);
    });
  }
  
  // 获取token
  getToken(uid, callback) {
    let obj = {
      contactId: uid,
      password: '84976492'
    };
    fetch.post(`${process.env.URL}/Auth/Login`, obj).then(res => {
      // console.log(res)
      if (res && res.success) {
        this.setUserInfo(res.data)
        this.setState({isAuthenticated: true})
      } else {
        console.log(res.message);
      }
      return callback? callback(): undefined;
    }).catch(err => {
      // console.log(err)
      // 退出登陆
      // this.props.userLogOut("redirect");
    })
  }

  // 校验登录 更新token
  checkLogin() {
    if (this.state.isChecking) return;
    this.setState({isChecking: true})
    fetch.post(`${process.env.URL}/Auth/refresh_token`).then(res => {
      // console.log(res)
      if (res && res.success) {
        this.setUserInfo(res.data)
      }
      this.setState({isChecking: false, isAuthenticated: true})
    }).catch(err => {
      // console.log(err)
      // 退出登陆
      this.props.userLogOut("redirect")
    })
  }

  // 设置用户信息
  setUserInfo(user) {
    let userName = '';
    let shortName = '';
    if (user.firstName) {
      userName = user.firstName;
      shortName = user.firstName.slice(0, 1);
    }
    if (user.lastName) {
      userName += userName ? ' ' : '' + user.lastName;
      shortName += user.lastName.slice(0, 1);
    }
    if (!user.firstName && !user.lastName) {
      userName = user.email.split('@')[0];
      shortName = user.email.slice(0, 1).toUpperCase();
    }
    user.name = userName;
    user.shortName = shortName;
    this.props.setUserData(user);
    this.props.setPlanData(user.pricingPlan || 0);
    this.setCookie('Authorization', user.token, 6);
    localStorage.setItem("token", user.token);
    localStorage.setItem("mailbox", user.email);
  }

  setCookie(key, value, hour) {
    var cookie = key + '=' + encodeURIComponent(value);
    if (hour > 0) {
      var date = new Date();
      date.setTime(date.getTime() + hour*3600*1000);
      // 设置作用域名
      // 判断当前域名是否为顶级域名
      let domain = window.location.hostname.split('.');
      if (domain.length > 2) {
        cookie += ';domain=' + domain[1] + '.' + domain[2];
      } else if (domain.length > 1) {
        cookie += ';domain=' + domain[0] + '.' + domain[1];
      }
      cookie += ';expires=' + date;
    }
    // console.log(cookie)
    document.cookie = cookie;
  }

  render() {
    return (
      <div className="app">
        {this.state.isEmbed?
          <Chat
            {...this.props}
            chatObj={this.state.chatObj}
            pId={this.state.pId}
            projectType={'File'}
            responeseType={'text'}
          ></Chat>
          : this.state.isAuthenticated ? 
            <Layout>
              {this.state.guideVisible ? 
                <>
                  <NavGuide {...this.props}></NavGuide>
                  <Layout className="site-layout">
                    <HeaderGuide {...this.props}></HeaderGuide>
                    <Content className='guide_content'>
                      {/* <Welcome></Welcome> */}
                      {this.props.children}
                    </Content>
                  </Layout>
                </>:
                <>
                  <Nav {...this.props}></Nav>
                  <Layout className="site-layout">
                    <Header {...this.props}></Header>
                    <Content className='app_content'>
                      {/* <Welcome></Welcome> */}
                      {this.props.children}
                    </Content>
                  </Layout>
                </>
              }
            </Layout>
            :null
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return state.reducer
}
const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(actions, dispatch)
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App)))