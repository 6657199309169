import React from 'react'
import { MenuFoldOutlined, MenuUnfoldOutlined, QuestionCircleOutlined, BulbOutlined, PlusCircleOutlined
  // SettingOutlined, UserOutlined, TeamOutlined 
} from '@ant-design/icons';
import { Layout, Menu, Button } from 'antd';
// import icon from '../images/icon.js';
// import fetch from '../fetch/axios';
import logo from '../images/NovaBot_logo.svg';
// import ic_plaza from '../images/icon/hd_ic_plaza.svg';
import ic_bot from '../images/icon/hd_ic_bot.svg';
import ic_support from '../images/icon/hd_ic_support.svg';
import '../style/nav.less';
import HeaderUsage from '../components/usage'
// import { createFromIconfontCN } from '@ant-design/icons';

// const MyIcon = createFromIconfontCN({
//   scriptUrl: '//at.alicdn.com/t/c/font_4036116_bbbix2q5bs9.js', // 在 iconfont.cn 上生成
// });

const { Sider } = Layout;

class NavigationBar extends React.Component {
  state = {
    pathname: "",
    selectedKeys: ['projects', 'dashboard'],
    role: "",
    lastOpen: ['square', 'dashboard'],
    
    changeNav: false, // 是否切换导航
  }
  componentDidMount() {
    // console.log(this.props);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location.pathname !== state.pathname) {
      return {
      }
    }
    if (props.user.role !== state.role) {
      return {
      }
    }
    return null
  }
  componentDidUpdate() {
    if (this.props.location.pathname !== this.state.pathname) {
      this.setState({ pathname: this.props.location.pathname }, () => {
        this.listenRoute(this.state.pathname);
      })
    }
    if (this.props.user.role !== this.state.role) {
      this.setState({ role: this.props.user.role })
    }
  }

  listenRoute(path) {
    if (path.includes('/create')) {
      this.setState({selectedKeys: []})
    } else if (path.includes('/projects/list')) {
      this.setState({selectedKeys: ['projects', 'dashboard']})
    } else if (path.includes('/faq')) {
      this.setState({selectedKeys: ['faq', 'support']})
    } else {
      this.setState({selectedKeys: ['dashboard']})
    }
    
    // if (path.includes('/chat-official')) {
    //   this.setState({selectedKeys: ['chat']})
    // } else if (path.includes('/square')) {
    //   this.setState({selectedKeys: ['square']})
    // } else if (path.includes('/s-create')) {
    //   this.setState({selectedKeys: ['s-create', 'square']})
    // } else if (path.includes('/my-square')) {
    //   this.setState({selectedKeys: ['my-square', 'square']})
    // // } else if (path.includes('/welcome')) {
    // //   this.setState({selectedKeys: ['welcome', 'support']})
    // } else if (path.includes('/system/ai')) {
    //   this.setState({selectedKeys: ['AISetting', 'settings']})
    // } else if (path.includes('/system/role')) {
    //   this.setState({selectedKeys: ['RoleSetting', 'settings']})
    // } else if (path.includes('/system/user')) {
    //   this.setState({selectedKeys: ['UserSetting', 'settings']})
    // }
  }

  changeNavPath(e) {
    console.log(e);
    // console.log(this.props.history);
    if (e.key === 'dashboard') {
      this.props.history.push('/projects/list');
    } else if (e.key === "faq") {
      this.props.history.push('/faq');
    } else if (e.key === "contact") {
      window.open("https://www.novagpt.ai/contact-us")
    }

    // if (e.key === 'create') {
    //   this.props.history.push('/create');
    // } else if (e.key === 'projects') {
    //   this.props.history.push('/projects/list');
    // } else if (e.key === 'chat') {
    //   this.props.history.push('/chat-official');
    // } else if (e.key === "square") {
    //   this.props.history.push('/square');
    // } else if (e.key === "s-create") {
    //   this.props.history.push('/s-create');
    // } else if (e.key === "my-square") {
    //   this.props.history.push('/my-square');
    // } else if (e.key === "welcome") {
    //   this.props.history.push('/welcome');
    // } else if (e.key === "AISetting") {
    //   this.props.history.push('/system/ai');
    // } else if (e.key === "RoleSetting") {
    //   this.props.history.push('/system/role');
    // } else if (e.key === "UserSetting") {
    //   this.props.history.push('/system/user');
    // }
    if (this.props.smallScreen) {
      this.props.setNavData({collapsed: true});
    }
  }

  changePath(url) {
    this.props.history.push(url);
  }
  closeNav(e) {
    if (e.target?.className === "navigation" && this.props.smallScreen) {
      this.props.setNavData({collapsed: true});
    }
  }
  clickSubMenu(arr) {
    let lastArr = this.state.lastOpen;
    // console.log(lastArr)
    // 点开了广场页面
    if (arr.includes('square') && !lastArr.includes('square')) {
      this.props.history.push('/square')
    }
    this.setState({lastOpen: arr})
  }

  render() {
    const { collapsed, smallScreen, moveToOpen, t } = this.props;
    const systemArr = [
      // {
      //   key: 'settings',
      //   icon: <SettingOutlined />,
      //   label: t('nav.Settings'),
      //   children: [
      //     {
      //       key: 'AISetting',
      //       icon: <UnorderedListOutlined />,
      //       label: t('nav.Al Promt'),
      //     },
      //     // {
      //     //   key: 'RoleSetting',
      //     //   icon: <UserOutlined />,
      //     //   label: t('nav.Role Management'),
      //     // },
      //     // {
      //     //   key: 'UserSetting',
      //     //   icon: <TeamOutlined />,
      //     //   label: t('nav.User Management'),
      //     // }
      //   ]
      // },
    ]
    const menuArr = [
      // {
      //   key: 'square',
      //   icon: <MyIcon className='leadIc' type="icon-fyguangchang" />,
      //   label: t('nav.Plaza'),
      // },
      // {
      //   key: 'chat',
      //   icon: <MyIcon className='leadIc' type="icon-xiaoxi-zhihui" />,
      //   label: t('nav.AI Assistant'),
      // },
      // {
      //   key: 'nav-plaza',
      //   icon: <img src={ic_plaza} alt=''></img>,
      //   label: t('nav.Bot Plaza'),
      // },
      {
        key: 'dashboard',
        icon: <img src={ic_bot} alt=''></img>,
        label: t('nav.My Bots'),
        // children: [
        //   {
        //     key: 'create',
        //     icon: <PlusOutlined />,
        //     label: t('nav.Create Bot'),
        //   },
        //   {
        //     key: 'projects',
        //     icon: <UnorderedListOutlined />,
        //     label: t('nav.My Bots'),
        //   }
        // ]
      },
      {
        key: 'support',
        icon: <img src={ic_support} alt=''></img>,
        label: t('nav.Support'),
        children: [
          // {
          //   key: 'welcome',
          //   icon: <ReadOutlined />,
          //   label: t('nav.Read Me'),
          // },
          {
            key: 'faq',
            icon: <QuestionCircleOutlined />,
            label: t('nav.FAQ'),
          },
          {
            key: 'contact',
            icon: <BulbOutlined />,
            label: t('nav.Contact Us'),
          }
        ]
      }
    ]
    return (
      <div className="navigation" open={!collapsed} onClick={(e)=>{ this.closeNav(e); }}>
        <Sider
          trigger={null}
          breakpoint='xl'
          onBreakpoint={(broken) => {
            this.props.setNavData({collapsed: broken, smallScreen: broken});
          }}
          width={'16.25rem'}
          collapsedWidth={smallScreen ? 0: '5.25rem'}
          collapsible
          collapsed={collapsed}
          onMouseEnter={()=>{
            if (smallScreen) return;
            this.props.setNavData({collapsed: false})
          }}
          onMouseLeave={(e)=>{
            if (smallScreen || !moveToOpen) return;
            this.props.setNavData({collapsed: true})
          }}
          style={{ background: "#111111" }}
          >
          <div className={collapsed ? 'logo_box collapsed':'logo_box'}>
            <div className='icon_box' onClick={()=>{ this.changePath("/") }}>
              <img src={logo}  className='logo' alt=''></img>
            </div>
            {/* <CloseOutlined className='nav_close'/> */}
            {React.createElement(collapsed || moveToOpen ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'nav_close',
              onClick: () => {
                if (!collapsed && moveToOpen) {
                  this.props.setNavData({collapsed: false, moveToOpen: false})
                  return
                }
                this.props.setNavData({collapsed: !collapsed, moveToOpen: !collapsed})
              },
            })}
          </div>
          <Button icon={<PlusCircleOutlined />} type='primary' className='nav_create' onClick={()=>{ this.changePath('/create') }} >{!collapsed&&t('nav.Create Bot')}</Button>
          <Menu
            theme="dark"
            mode="inline"
            // defaultSelectedKeys={}
            defaultOpenKeys={['square', 'dashboard', 'support', 'settings']}
            selectedKeys={this.state.selectedKeys}
            style={{ fontSize: ".9375rem", color: "#6f6b7d", background: "#111111" }}
            onClick={(e)=>{ this.changeNavPath(e) }}
            onOpenChange={(e)=>{ this.clickSubMenu(e) }}
            items={ this.state.role === "System" ?  [...menuArr, ...systemArr] : [...menuArr]}
          />
          <HeaderUsage {...this.props} showWidth={1024} className={collapsed ? 'collapsed':''}></HeaderUsage>
          <div className={collapsed ? 'nav_power collapsed':'nav_power'}>
            {t('nav.Power 1')} <a href="https://www.novagpt.ai/" target="_blank" rel="noopener noreferrer">{t('nav.NovaTech')}</a> {t('nav.Power 2')}
          </div>
        </Sider>
      </div>
    )
  }
}

export default NavigationBar;