import React from 'react';
// import { Button } from 'antd';
import { Dropdown, Button, Popover, Input, message } from 'antd';
import { UserOutlined, StarFilled, StarOutlined, SettingOutlined, LeftOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import '../style/component.less';
import fetch from '../fetch/axios';
import EmojiPicker from 'emoji-picker-react';

const { TextArea } = Input;

class chatHelper extends React.Component {
  state = {
    tabIndex: 0,
    helperData: [],
    helperChildData: [],
    helperOption: {},
    showOpt: false,
    showAdd: false,
    showDel: false,
    showEmoji: false,
    dropDownOpen: false,

    newEmoji: "",
    newTitle: "",
    newPromt: "",
    isOperating: false,
    editingRowIndex: undefined,
    editingItem: {},
  }

  componentDidMount() {
    this.getHelperList();
  }
  // 获取本地设置
  getHelperOption() {
    let helperOption = JSON.parse(window.localStorage?.getItem('helperOption')||"{}");
    if (helperOption) {
      let helperData = this.state.helperData;
      Object.keys(helperOption).forEach((item, index) => {
        helperData.forEach((it, i) => {
          if (it.id === parseInt(item)) {
            let child = it.child.find(cld=>cld.id === helperOption[item]);
            it.img = child.img;
            it.title = child.title;
          }
        })
      })
      this.setState({ helperOption });
    }
  }

  getHelperList() {
    fetch.get(`${process.env.URL}/Chat/GPTTemplater`).then(res => {
      if (res && res.success) {
        // console.log(res);
        this.setState({
          helperData: res.data || [],
        },()=>{
          this.getHelperOption();
        })
      } else {
        console.log(res.message);
      }
    })
  }
  // 收藏
  markStar(helpD) {
    // console.log(helpD);
    let obj = {
      id: helpD.id,
    }
    fetch.post(`${process.env.URL}/Chat/GPTTemplater/Toggle`, obj).then(res => {
      if (res && res.success) {
        // console.log(res);
        this.setState({
          helperData: res.data || [],
        })
      } else {
        console.log(res.message);
      }
    })
  }
  // 下拉框
  changeDrop(e) {
    this.setState({dropDownOpen: e})
    // 延迟关闭设置框
    if (!e) {
      setTimeout(() => {
        this.setState({showOpt: false, showAdd: false, showEmoji: false, showDel: false})
      }, 200);
    }
  }
  // 设置
  openOpt(it, id) {
    console.log(it, id)
    // let arr = this.state.helperData;
    // // console.log(it, id, arr[id])
    this.setState({
      helperChildData: it?.child || [],
      showOpt: !this.state.showOpt,
      dropDownOpen: true
    });
  }
  // 设置子项
  setOption(it) {
    let opt =JSON.parse(window.localStorage?.getItem('helperOption') || "{}");
    opt[it.parent_id] = it.id;
    window.localStorage?.setItem('helperOption', JSON.stringify(opt));

    let helperData = this.state.helperData;
    helperData.forEach((item, index) => {
      if (item.id === it.parent_id) {
        item.img = it.img;
        item.title = it.title;
      }
    });
    this.setState({helperData});
  }

  helperClick(item) {
    // console.log(item);
    this.props.helperSearch(item?.id || 0, item);
  }

  handleAdd() {
    this.setState({ showAdd: !this.state.showAdd, showDel: false, editingRowIndex: undefined});
  }

  editItem(item, index) {
    this.setState({
      editingRowIndex: index,
      editingItem: item,
      newEmoji: item.img,
      newTitle: item.title,
      newPromt: item.prompts,
      showAdd: true,
      showDel: true,
    })
  }

  handleEmojiClick() {
    this.setState({ showEmoji: !this.state.showEmoji });
  }
  onEmojiClick (emojiObject, event) {
    this.setState({ newEmoji: emojiObject.emoji, showEmoji: false });
  }

  handlenewTitleChange(e) {
    this.setState({ newTitle: e.target.value });
  }

  handlenewPromtChange(e) {
    this.setState({ newPromt: e.target.value });
  }

  // 添加或编辑
  handleAddConfirm() {
    const { newEmoji, newTitle, newPromt, editingRowIndex, isOperating, editingItem } = this.state;
    if ( !newEmoji.trim() || !newTitle.trim() || !newPromt.trim() || isOperating) return;
    this.setState({isOperating: true})

    let data = {
      id: editingRowIndex>=0 ? editingItem.id : "-1",
      img: newEmoji,
      title: newTitle,
      prompts: newPromt,
      sort_templater: editingRowIndex>=0 ? editingItem.sort_templater : "0",
      isSystem: false
    }

    console.log(data, editingRowIndex)

    fetch.post(`${process.env.URL}/Chat/GPTTemplater/Save`, data).then(res => {
      if (res) {
        let { helperData } = this.state;
        if (editingRowIndex>=0) {
          // 更新数据
          // let row = editingItem;
          // row.img = newEmoji;
          // row.title = newTitle;
          // row.prompts = newPromt;
          helperData[editingRowIndex] = res.data;
          this.setState({
            helperData,
            // editingRowIndex: undefined,
            isOperating: false
          }, ()=>{
            this.handleReset();
          });
          
        } else {
          // 新增数据
          let newEntry = res.data;
          console.log(newEntry)
          if (newEntry) {
            helperData.push(newEntry);
          }
          this.setState({
            helperData,
            isOperating: false,
          }, ()=>{
            this.handleReset();
          });
        }
      } else {
        // console.log(res.message);
        this.setState({ isOperating: false });
      }
    })
  }

  handleReset() {
    this.setState({ showAdd: false, showDel: false, newEmoji: "", newTitle: "", newPromt: "" });
  }

  deleteQuestion() {
    const { editingRowIndex, editingItem, isOperating, helperData } = this.state;
    if (isOperating && editingRowIndex === undefined) return;
    this.setState({isOperating: true})
    fetch.post(`${process.env.URL}/Chat/GPTTemplater/Delete/${editingItem.id}`).then(res => {
      if (res && res.success) {
        helperData.splice(editingRowIndex, 1);
        this.setState({
          helperData,
          editingRowIndex: undefined,
          editingItem: {},
          isOperating: false
        }, ()=>{
          this.handleReset();
        });
      } else {
        // console.log(res.message);
        this.setState({ isOperating: false });
      }
    })
  }

  render() {
    const { t } = this.props;
    return (
      <div className="chat_helper">
        <div className='chat_lt'>
          {this.state.helperData.map((item, index) => {
            if (index >= 4) return null;
            return (
              <div className={`chat_helper_item`} key={index} onClick={() => {
                if (item.child?.length > 0) {
                  // 如果有子项
                  if (this.state.helperOption[item.id]) {
                    // 且设置过子项
                    let childid = this.state.helperOption[item.id];
                    let child = item.child.find(it => it.id === childid);
                    if (child) this.helperClick(child);
                  } else {
                    // 未设置过子项 默认第一个
                    this.helperClick(item.child[0]);
                    this.setOption(item.child[0]);
                  }
                } else {
                  this.helperClick(item)
                }
              }}>
                <div className='chat_emoji'>{item.img}</div>
                {item.title}
                <div className='chat_h_opt_box'>
                  {item.child?.length > 0 ?
                    <div className='chat_h_setting' onClick={(e)=>{ e.stopPropagation(); this.openOpt(item, index) }}>
                      <SettingOutlined />
                    </div>
                  : null}
                </div>
              </div>
            )
          })}
        </div>
        <div className='chat_gt'>
          <Dropdown
            trigger={['click']}
            open={this.state.dropDownOpen}
            onOpenChange={(e)=>{ this.changeDrop(e) }}
            dropdownRender={menu => {
              return (
                <div className='chat_h_more_dropdown'>
                  <div className='chat_h_more_title'>{t('chat.asis.assistant')}
                    <Button className='middle' type="primary" onClick={() => { this.handleAdd() }} icon={<PlusOutlined />}>{t("chat.asis.Add")}</Button>
                  </div>
                  <div className='chat_h_more_main'>
                    {this.state.helperData.map((item, index) => {
                      return (
                        <div className={`chat_helper_d_item`} key={index} onClick={() => {
                          if (item.child?.length > 0) {
                            // 如果有子项
                            if (this.state.helperOption[item.id]) {
                              // 且设置过子项
                              let childid = this.state.helperOption[item.id];
                              let child = item.child.find(it => it.id === childid);
                              if (child) this.helperClick(child);
                            } else {
                              // 未设置过子项 默认第一个
                              this.helperClick(item.child[0]);
                              this.setOption(item.child[0]);
                            }
                          } else {
                            this.helperClick(item)
                          }
                        }}>
                          <div className='chat_emoji'>{item.img}</div>
                          {item.title}
                          <div className='chat_h_opt_box'>
                            <div className='chat_h_star' onClick={(e)=>{
                              e.stopPropagation();
                              this.markStar(item);
                            }}>
                              {item.user_collect ? <StarFilled className='stared'/>: <StarOutlined/>}
                            </div>
                            {item.child?.length > 0 ?
                              <div className='chat_h_setting' onClick={(e)=>{ e.stopPropagation(); this.openOpt(item, index) }}>
                                <SettingOutlined />
                              </div>
                            : null}
                            {item.isSystem ? null :
                              <div className='chat_h_edit' onClick={(e)=>{ e.stopPropagation(); this.editItem(item, index) }}>
                                <EditOutlined />
                              </div>
                            }
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  {/* 设置 */}
                  <div className={this.state.showOpt ? "chat_h_more_option show":'chat_h_more_option'}>
                    <div className='chm_header'><LeftOutlined onClick={()=>{ this.openOpt() }}/> {t('chat.asis.Setting')} </div>
                    {this.state.helperChildData.map((it, i)=>{
                      return (
                        <div className='chat_helper_d_item' key={i} onClick={()=>{
                          this.helperClick(it);
                          this.setOption(it);
                        }}>
                          <div className='chat_emoji'>{it.img}</div>
                          {it.title}
                        </div>
                      )
                    })}
                  </div>
                  {/* 新增 */}
                  <div className={this.state.showAdd ? "chat_h_more_option show":'chat_h_more_option'} style={{zIndex: 2}}>
                    <div className='chm_header'><LeftOutlined onClick={()=>{ this.handleAdd() }}/> {t('chat.asis.Add title')} </div>
                    <div className='editor_box'>
                      <div className='eb_title' style={{ margin: ".5rem 0" }}>{t("system.AI.Emoji")}</div>
                      <Popover
                        trigger={['click']}
                        open={this.state.showEmoji}
                        content={
                          <EmojiPicker
                            onEmojiClick={(emoji,e)=>{ this.onEmojiClick(emoji,e) }}
                            searchDisabled={true}
                            skinTonesDisabled={true}
                            previewConfig={{ showPreview: false }}
                            height={"18.75rem"}
                          ></EmojiPicker>
                        }
                      >
                        <Input
                          value={this.state.newEmoji}
                          onClick={()=>{ this.handleEmojiClick() }}
                          placeholder={t("system.AI.Emoji Des")}
                        />
                      </Popover>
                      <div className='eb_title' style={{ margin: ".5rem 0" }}>{t("system.AI.pTitle")}</div>
                      <TextArea
                        value={this.state.newTitle}
                        onChange={(e)=>{ this.handlenewTitleChange(e) }}
                        placeholder={t("system.AI.pTitle Des")}
                        autoSize={{
                          minRows: 1,
                          maxRows: 5,
                        }}
                      />
                      <div className='eb_title' style={{ margin: ".5rem 0" }}>{t("system.AI.Promt")}</div>
                      <TextArea
                        value={this.state.newPromt}
                        onChange={(e)=>{ this.handlenewPromtChange(e) }}
                        placeholder={t("system.AI.Promt Des")}
                        autoSize={{
                          minRows: 1,
                          maxRows: 5,
                        }}
                      />
                    </div>
                    <div className='opt_box'>
                      <Button size='middle' onClick={()=>{ this.handleReset() }}>{t("system.AI.Cancel")}</Button>
                      {this.state.showDel &&
                        <Button size='middle' onClick={()=>{ this.deleteQuestion() }}>{t("system.AI.Delete")}</Button>
                      }
                      <Button size='middle' type="primary" onClick={()=>{ this.handleAddConfirm() }}>{t("system.AI.Confirm")}</Button>
                    </div>
                  </div>
                  {/* emoji选择器 */}
                  {/* <div className={this.state.showEmoji ? "chat_h_more_option show":'chat_h_more_option'} style={{zIndex: 3}}>
                    <div className='chm_header'><LeftOutlined onClick={()=>{ this.handleEmojiClick() }}/> {t('chat.asis.Add title')} </div>
                      {this.state.showEmoji &&
                        <EmojiPicker
                          onEmojiClick={(emoji,e)=>{ this.onEmojiClick(emoji,e) }}
                          pickerStyle={{ width: '100%', height: '100%' }}
                        ></EmojiPicker>
                      }
                  </div> */}
                </div>
              )
            }}
            placement="topRight"
          >
            <div className='chat_h_more_btn'>
              <UserOutlined className='chat_h_more_icon'/>
              {/* <CheckOutlined />
               */}
               <div className='chat_h_more_btn_text'>{t('chat.asis.More')}</div>
            </div>
          </Dropdown>
        </div>
        
      </div>
    )
  }
}

export default chatHelper