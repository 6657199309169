import React from 'react';
import fetchAxios from '../../fetch/axios';
import '../../style/chat.less';
import moment from 'moment'
import BotAvt from '../../images/chatbot_avatar.png';
import { Input, Avatar, Button, message } from 'antd';
import { SearchOutlined, RightOutlined, RedoOutlined, UnorderedListOutlined, LineOutlined, CopyOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import FilePreview from "../../components/filePreview";
import ChatHelper from '../../components/chatHelper';
import QAModule from './qaModoule';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {coldarkDark} from 'react-syntax-highlighter/dist/esm/styles/prism'

const { TextArea } = Input;

class Chat extends React.Component {
  state = {
    pId: "",
    chatMsg: [],
    askMsg: "",
    isGenerating: false,
    tempAsk: "",
    tempAnswer: "",
    tempAnswerArr: [],
    chatObj: {
      subjectGUID: "",
    },
    newId: "",
    isNew: false,
    subId: "",
    isAddingMsg: false,
    isTyping: false,
    pSetting: {},
    defaultQuestion: [],

    showMoreQuestion: false, //是否显示更多问题
    isGettingDefaultQuestion: false, //是否正在获取默认问题
    imgLoadedArr: [], //图片是否加载完成
  }
  childRef = React.createRef();
  componentDidMount() {
  }
  static getDerivedStateFromProps(props, state) {
    if (props.chatObj.subjectGUID !== state.chatObj.subjectGUID) {
      return {
      }
    }
    if (props.pId !== state.pId) {
      return {
      }
    }
    return null
  }
  componentDidUpdate() {
    if (this.props.chatObj.subjectGUID !== this.state.chatObj.subjectGUID) {
      this.setState({ chatObj: this.props.chatObj, isGenerating: false }, () => {
        // 清空历史记录
        // console.log(this.state.chatObj.subjectGUID)
        if (this.state.chatObj.subjectGUID) {
          // 如果不是新建转已有，则先清空，新建转已有则不清空
          if (!this.props.chatObj?.isCreated) {
            this.setState({chatMsg:[]})
            this.getDefaultQuestion(this.state.chatObj.subjectGUID);
          }
          this.setState({newId: ""})
          // 有id获取历史记录 默认问题
          this.getHistory()
        } else {
          // 无id生成id
          let newId = this.getGuid();
          this.setState({chatMsg:[], newId});
          this.getDefaultQuestion(newId);
        }
      })
    }
    if (this.props.pId !== this.state.pId) {
      this.setState({pId: this.props.pId},()=>{
        this.getProjectConfig();
      });
    }
  }
  // 获取唯一ID
  getGuid() {
    return 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random()*16|0, v = c === 'x' ? r : ((r&0x3)|0x8);
      return v.toString(16);
    });
  }
  keyListen(e) {
    // console.log(e)
    if (e.key === "Enter" && !e.shiftKey && !e.ctrlKey && !e.metaKey) {
      this.search();
    }
  }
  // 获取项目配置
  getProjectConfig() {
    if (!this.state.pId) return;
    fetchAxios.get(`${process.env.URL}/Project/Setting/${this.state.pId}`).then((res)=>{
      if (res && res.success) {
        if (res.data?.project_name) {
          document.title = "NovaGPT - " + res.data.project_name;
        }
        this.setState({
          pSetting: res?.data || {}
        })
      }
    })
  }
  // 获取历史
  getHistory() {
    // console.log("obj", this.state.chatObj);
    // 查询历史记录
    fetchAxios.get(`${process.env.URL}/conversations/${this.state.chatObj.subjectGUID}`).then((res)=>{
      if (res && res.success) {
        // console.log(res.data);
        this.setState({
          chatMsg: res.data,
        })
        this.scrollToBottom();
      } else {
        this.setState({chatMsg:[]})
      }
    })
  };
  // 获取默认问题
  getDefaultQuestion(sId, type) {
    if (this.state.isGettingDefaultQuestion) return;
    this.setState({isGettingDefaultQuestion: true})
    // type=all 全部问题 type=refresh 刷新 type=default 默认
    // refresh时，额外传入最后一条问题的id
    let str = type === "refresh" ? `?type=refresh&id=${this.state.defaultQuestion[this.state.defaultQuestion.length-1]?.sort_question}` : 
      type === "all" ? "?type=all" : "?type=default";
    // this.setState({defaultQuestion: []})
    fetchAxios.get(`${process.env.URL}/Project/${this.state.pId}/SuggestQuestions/${sId}${str}`).then((res)=>{
      if (res && res.success && res.data) {
        this.setState({
          defaultQuestion: res.data,
          isGettingDefaultQuestion: false
        });
        if (type === "all") {
          this.setState({
            showMoreQuestion: true
          });
        } else {
          this.setState({
            showMoreQuestion: false
          });
        }
      }
    })
  }
  // 收起默认问题
  closeQuestions(){
    let num = this.state.pSetting?.firstQuestionShowNum || 5;
    this.setState({
      defaultQuestion: this.state.defaultQuestion.slice(0, num),
      showMoreQuestion: false
    })
  }
  getCookie(name) {
    let arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    arr = document.cookie.match(reg)
    return arr ? unescape(arr[2]) : null;
  }
  resetChat() {
    this.setState({
      tempAnswerArr: [],
      tempAnswer: '',
      isGenerating: false,
    })
    // if (this.state.isNew) {
    //   this.props?.creating(this.state.subId);
    //   this.setState({isNew: false, subId: ""});
    // }
  }
  // 查询问题
  search(askStr, questionid, helperId, helpItem) {
    
    // 传了askStr则为特殊问答，否则为用户输入问题
    if ((!askStr && !this.state.askMsg.trim() && !helperId) || this.state.isGenerating) return;

    // 初始化回复模块
    this.setState({
      isGenerating: true,
      askMsg: "",
      tempAsk: askStr || this.state.askMsg.trim(),
      tempAnswer: "Hang in there! I'm thinking...",
    }, () => { this.scrollToBottom(); });

    let searchObj = {
      "role": "user",
      "content": askStr || this.state.askMsg.trim(),
      "questionid": questionid || "", // 选中默认问题时的id
    }
    //助手问答，有askMsg则针对askMsg回复，无则针对上一条问题回复
    if (helperId) {
      searchObj["templaterid"] = helperId;
    }
    let subId = this.state.chatObj.subjectGUID || this.state.newId;
    this.setState({isNew: subId === this.state.newId, subId});

    // console.log(this.props.responeseType)
    if (this.props.responeseType === 'text') {
      // 普通接口
      fetchAxios.post(`${process.env.URL}/${this.props.official? "" : `ask/${this.props.pId}/`}Chat/${subId}`, searchObj).then((res)=>{
        if (res) {
          this.setState({tempAnswer: ""})

          // 先处理标注内容
          this.typeWriter(res.answer, 50, ()=>{
            // 新建则父组件重新获取列表
            if (subId === this.state.newId) {
              this.props.creating?.(subId);
            }
            // console.log(res?.suggestquestions)
            // 将回答输出
            let resObj = {
              "question": this.state.tempAsk,
              "answer": this.state.tempAnswer,
              "cDate": moment().format(),
              "suggestQuestions": res?.suggestquestions||[],
              "source_files": res?.source_files||[],
            };
            
            this.setState({
              chatMsg: this.state.chatMsg.concat(resObj),
              tempAsk: "",
              tempAnswer: "",
              isGenerating: false,
            },()=>{
              // console.log(this.state.chatMsg)
            })
          });
        }
      })
    } else {
      // 流式输出
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'bearer ' + this.getCookie('Authorization') || localStorage.getItem("token"),
        },
        responseType: 'stream',
        body: JSON.stringify(searchObj)
      };
      fetch(`${process.env.URL}/${this.props.official? "" : `Project/${this.props.pId}/`}Chat/${subId}`, requestOptions).then((response) => {
        const reader = response.body.getReader();
        let isDone = false;
        this.setState({tempAnswer: ""})

        let arrTemp = new Uint8Array([]);
        const readChunk = () => {
          reader.read().then(({ value, done }) => {
            // 切换会话，重置
            if (subId !== (this.state.chatObj.subjectGUID || this.state.newId)) {
              this.resetChat();
              return;
            }
            // console.log("=========================")
            // console.log(arrTemp)
            // console.log(value, done)
            let mergedArray;
            if (value) {
              mergedArray = new Uint8Array(arrTemp.length + value?.length);
              mergedArray.set(arrTemp);
              mergedArray.set(value, arrTemp.length)
            } else {
              mergedArray = new Uint8Array(arrTemp.length);
              mergedArray.set(arrTemp);
            }
            let text = new TextDecoder('utf-8').decode(mergedArray);
            if (value) {
              text = text?.slice(0, -2);
              let brforeCode = new TextEncoder('utf-8').encode(text);
              // console.log(brforeCode)
              arrTemp = mergedArray.subarray(brforeCode.length);
            }
            // console.log(text)
            
            this.setState({isAddingMsg: true}, ()=>{
              this.setState({
                tempAnswerArr: this.state.tempAnswerArr.concat(text),
                isAddingMsg: false,
              })
            })
            if (done) {
              isDone = true;
              return;
            }
            readChunk();
          }).catch((err) => {
            console.error(`Error: ${err}`);
          });
        };
        readChunk();
        // 打印输出
        let typeWriterTimer =  setInterval(() => {
          // 切换会话，重置
          if (this.state.subId !== (this.state.chatObj.subjectGUID || this.state.newId)) {
            this.resetChat();
            clearInterval(typeWriterTimer)
            return;
          }
          // 如果不在打印，则开始打印下一段 
          if (!this.state.isTyping) {
            let tempArr = this.state.tempAnswerArr;
            let firstItem = tempArr.shift();
            if (firstItem) {
              this.setState({
                tempAnswerArr: tempArr,
                isTyping: true
              });
              this.typeWriter(firstItem, 50);
            }
          }
          // 完成打印
          if (this.state.tempAnswerArr.length === 0 && !this.state.isAddingMsg && !this.state.isTyping && isDone ) {
            // 若为新建则父组件重新获取列表
            if (subId === this.state.newId) {
              this.props.creating?.(subId);
            }
            clearInterval(typeWriterTimer);
  
            // 将回答输出
            let resObj = {
              "question": this.state.tempAsk,
              "answer": this.state.tempAnswer,
              "cDate": moment().format()
            };
            if (helperId) {
              resObj["chatTemplater_ID"] = helperId;
              resObj["chatTemplater_Img"] = helpItem.img;
              resObj["chatTemplater_Title"] = helpItem.title;
            }
            // console.log(resObj)
            this.setState({
              chatMsg: this.state.chatMsg.concat(resObj),
              tempAsk: "",
              tempAnswer: "",
              isGenerating: false,
            })
          }
        }, 100);
      })
    }
  }
  // 打字机效果
  typeWriter(text, speed, callback) {
    // console.log(text)
    let sum = 0;
    const intervalId = setInterval(() => {
      // 切换会话，重置
      if (this.state.subId !== (this.state.chatObj.subjectGUID || this.state.newId)) {
        this.resetChat();
        clearInterval(intervalId);
        this.setState({isTyping: false})
        return;
      }
      let step = Math.floor(Math.random() * (6)) + 2;
      if (sum < text.length) {
        // 检测图片、视频或音频标签
        const mediaRegex = /<img[\s\S]*?>|<video[\s\S]*?>|<audio[\s\S]*?>/g;
        const match = mediaRegex.exec(text.slice(sum));
        if (match?.index <= step) {
          // 直接输出图片标签
          const imgTag = match[0];
          this.setState({ tempAnswer: this.state.tempAnswer + text.slice(sum, sum + match.index) + imgTag }, () => { console.log(this.state.tempAnswer) });
          sum = sum + match.index + imgTag.length;
        } else {
          // 输出文字
          this.setState({ tempAnswer: this.state.tempAnswer + text.slice(sum, sum + step) }, 
          // () => { console.log(this.state.tempAnswer) }
          )
          sum += step;
        }
      } else {
        clearInterval(intervalId);
        this.setState({isTyping: false})
        if (callback) {
          callback()
        }
      }
    }, speed);
  }
  // 滚动到底部
  scrollToBottom() {
    let ele = document.querySelector(".cp_chat_main");
    if (ele) ele.scrollTop = ele.scrollHeight;
  }
  viewFile(fileInfo) {
    this.childRef.current.getFile(fileInfo.fileid, fileInfo.filename);
  }
  // 助手问答
  helperSearch(id, helper) {
    // console.log(id);
    this.search("", "", id, helper)
  }
  getForm(fData) {
    // console.log(fData);
    let resObj = {
      "question": "",
      "answer": "",
      "cDate": moment().format(),
      "suggestQuestions": [],
      "source_files": [],
      "searchType": "form",
      "formData": fData,
    };
    this.setState({chatMsg: this.state.chatMsg.concat(resObj),})
  }

  render() {
    // console.log(this.props)
    const { user } = this.props.reducer;
    const { t } = this.props;

    return (
      <div className='cp_chat'>
        <div className='cp_chat_main'>
          {this.state.pSetting?.firstMessage &&
            <div className='chat_item'>
              {/* 问候语  */}
              <div className='chat_bot'>
                <div className='chat_bot_icon'>
                  <img src={BotAvt} alt=''></img>
                </div>
                <div className='chat_box_bubble'>
                  <div className='chat_bot_msg pre_line'>
                    {this.state.pSetting.firstMessage}
                  </div>
                </div>
              </div>
              {/* 默认问题 */}
              {this.props.responeseType === 'text' && this.state.defaultQuestion.length > 0 &&
                <div className='chat_bot'>
                  <div className='chat_bot_icon'>
                    <img src={BotAvt} alt=''></img>
                  </div>
                  <div className='chat_box_bubble'>
                    <div className='chat_bot_msg pre_line'>
                      <div className='chat_bot_opt'>
                        <RedoOutlined onClick={()=>{ this.getDefaultQuestion(this.state.chatObj.subjectGUID || this.state.newId, 'refresh') }}/>
                        {this.state.showMoreQuestion ?
                          <LineOutlined onClick={()=>{ this.closeQuestions() }}/> :
                          <UnorderedListOutlined onClick={()=>{ this.getDefaultQuestion(this.state.chatObj.subjectGUID || this.state.newId, 'all') }}/>
                        }
                      </div>
                      <div>{this.state.pSetting?.questionShowMessage || "Follow-up questions:"}</div>
                      <div className='chat_bot_questions'>
                        {this.state.defaultQuestion.map((it, id) => {
                          return(
                            <div
                              className='chat_bot_ques_item'
                              key={id}
                              onClick={()=>{
                                // console.log(it)
                                if (it.text_Type === "Forms" && it.forms) {
                                  this.getForm(it.forms)
                                } else {
                                  this.search(it.question, it.number)
                                }
                              }}
                              title={it.question}
                            >
                              <div>{it.question}</div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          }
          {/* 问答记录 */}
          {this.state.chatMsg.map((item, index) => {
            return (
              <QAModule
                key={index}
                data={item}
                index={index}
                {...this.props}
                search={(q, n)=>{ this.search(q, n) }}
                getForm={(fData)=>{ this.getForm(fData) }}
                viewFile={(e)=>{ this.viewFile(e) }}
                scrollToBottom={()=>{ this.scrollToBottom() }}
              ></QAModule>
            )
          })}
          {/* 生成回答中 */}
          {this.state.isGenerating ?
            <div className='chat_item'>
              {this.state.tempAsk.trim() !== "" &&
                <div className='chat_me'>
                  <Avatar className='chat_me_icon'>{user.shortName}</Avatar>
                  {/* <div className='chat_me_icon'>C</div> */}
                  <div className='chat_me_msg'>{this.state.tempAsk}</div>
                </div>
              }
              <div className='chat_bot'>
                <div className='chat_bot_icon'>
                  <img src={BotAvt} alt='' ></img>
                </div>
                {/* <div className='chat_bot_msg'>{this.state.tempAnswer}</div> */}
                <div className='chat_box_bubble'>
                  <div className='chat_bot_msg generating'>
                    <ReactMarkdown 
                      className='markdown'
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[rehypeRaw]}
                      components={{
                        'em': ({ node, ...props }) => {
                          
                          if (props.children[0] && typeof props.children[0] === 'string' && props.children[0].startsWith('^sup')) {
                            return <sup>{props.children[0].substring(4)}</sup>
                          }
                          if (props.children[0] && typeof props.children[0] === 'string' && props.children[0].startsWith('~sub')) {
                            return <sub>{props.children[0].substring(4)}</sub>
                          }
                          return <em {...props} />
                        },
                        // 'img': ({ node, ...props }) => {
                        //   // console.log(node, props)
                        //   return <LazyImage {...props} />
                        // },
                        pre({ children }) {
                          const match = /language-(\w+)/.exec(children[0]?.props?.className || '')
                          return <div className='chat_code_box'>
                            <div className='chat_code_header'>
                              <div>{match?.[1]}</div>
                              <Button
                                onClick={()=>{ 
                                  navigator.clipboard.writeText(children[0].props.children[0]);
                                  message.success('Copied to clipboard.')
                                }}
                                icon={<CopyOutlined />}
                                size='small'
                                type='text'
                              >Copy code</Button>
                            </div>
                            {children}
                          </div>
                        },
                        code({node, inline, className, children, ...props}) {
                          const match = /language-(\w+)/.exec(className || '')
                          return !inline ? (
                            <SyntaxHighlighter
                              {...props}
                              children={String(children).replace(/\n$/, '')}
                              style={coldarkDark}
                              language={match?.[1] || undefined}
                              PreTag="div"
                            />
                          ) : (
                            <code {...props} className={className}>
                              {children}
                            </code>
                          )
                        }
                      }}
                    >
                      {this.state.tempAnswer}
                    </ReactMarkdown>
                  </div>
                </div>
              </div>
              {/* <div className='cp_progress'>
                <div className='cp_pgs_bg'></div>
                <div className='cp_pgs_bar'>
                  <div className='cp_pgs_ani long'></div>
                  <div className='cp_pgs_ani short'></div>
                  <div></div>
                </div>
              </div> */}
            </div>
          : null}
        </div>
        <div className='cp_chat_ask'>
          <div className='cp_chat_ask_main' onKeyDown={(e) => { this.keyListen(e) }}>
            <div className='cp_chat_ask_top'>
              <SearchOutlined/>
              <TextArea
                value={this.state.askMsg}
                onChange={(e) => this.setState({ askMsg: e.target.value })}
                placeholder={t('chat.Ask me')}
                autoSize={{
                  minRows: 1,
                  maxRows: 5,
                }}
                disabled={this.state.isGenerating}
              />
            </div>
            <div className='cp_chat_ask_bottom'>
              <div className='cp_chat_ask_counter'>{this.state.askMsg.length}/1000</div>
              {this.props.responeseType === 'stream' &&
                <ChatHelper helperSearch={(id, helper)=>{ this.helperSearch(id, helper) }} t={this.props.t}></ChatHelper>
              }
              <div className='cp_chat_ask_enter' onClick={() => this.search()}>
                <RightOutlined />
              </div>
            </div>
          </div>
        </div>
        <div className='cp_chat_power' >
          <a href='https://www.novagpt.ai' target='__blank'>{t('chat.Powered')}</a>
        </div>
        <FilePreview ref={this.childRef}></FilePreview>
      </div>
    )
  }
}

export default Chat