import React from 'react'
import { MenuFoldOutlined, MenuUnfoldOutlined, PlusOutlined, UserOutlined, MoneyCollectOutlined, CreditCardOutlined, LogoutOutlined, DownOutlined } from '@ant-design/icons';
import { Layout, Button, Avatar, Popover, Dropdown } from 'antd';
import '../style/header.less';
import i18n from 'i18next';

const { Header } = Layout;

class HeaderBar extends React.Component {
  state = {
    language: 'en',
    showLanguage: "En",
  }
  componentDidMount() {
    
  }
  
  changePath(url) {
    this.props.history.push(url);
  }

  changeLanguage(lan) {
    if (lan === 'en') {
      i18n.changeLanguage('zh');
      this.setState({language: 'zh', showLanguage: "中文"})
    } else if (lan === 'zh') {
      i18n.changeLanguage('en');
      this.setState({language: 'en', showLanguage: "En"})
    }
  }

  render() {
    const {collapsed, smallScreen, moveToOpen, user, t, headerTitle} = this.props;
    const content = (
      <div className='header_dropdown'>
        <div className='hd_header'>
          <div className='dropdown_btn'>
            <Avatar className='person_box'>{user.shortName}</Avatar>
            <div className='person_name'>{user.name}</div>
          </div>
        </div>
        <div className='hd_line'></div>
        <div className='hd_body'>
          <div className='dropdown_btn' onClick={()=>{ this.changePath('/profile') }}><UserOutlined />{t('header.My Profile')}</div>
          <div className='dropdown_btn' onClick={()=>{ this.changePath('/pricing') }}><MoneyCollectOutlined />{t('header.Pricing')}</div>
          <div className='dropdown_btn' onClick={()=>{ this.changePath('/billing') }}><CreditCardOutlined />{t('header.Billing')}</div>
        </div>
        <div className='hd_line'></div>
        <div className='hd_bottom'>
          <div className='dropdown_btn' onClick={() => {
            // 退出登录
            this.props.userLogOut()
          }}><LogoutOutlined />{t('header.Log Out')}</div>
        </div>
      </div>
    );
    const items = [
      {
        label: <div onClick={()=>{ this.changeLanguage('zh') }}>English</div>,
        key: 'En',
      },
      {
        label: <div onClick={()=>{ this.changeLanguage('en') }}>中文</div>,
        key: 'Zh',
      },
    ];
    return (
      <div className="header_bar">
        <Header>
          <div className='header_lt'>
            {smallScreen ? React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'nav_close',
              onClick: () => {
                if (!collapsed && moveToOpen) {
                  this.props.setNavData({collapsed: false, moveToOpen: false})
                  return
                }
                this.props.setNavData({collapsed: !collapsed, moveToOpen: !collapsed})
              },
            }): null}
            <div className='header_title'>{headerTitle}</div>
          </div>
          <div className='header_gt'>
            {/* <Button type="primary" icon={<PlusOutlined />} className='add_btn' onClick={()=>{ this.changePath('/create') }}>
              {t('header.Create Bot')}
            </Button> */}
            <Dropdown
              menu={{
                items,
              }}
            >
              <div className='header_language'>
                {this.state.showLanguage}
                <DownOutlined />
              </div>
            </Dropdown>
            <Popover content={content} trigger="click" arrow={false} placement="bottomRight">
              <Avatar className='person_box'>{user.shortName}</Avatar>
            </Popover>
          </div>
        </Header>
      </div>
    )
  }
}

export default HeaderBar